export const RootRoutes = Object.freeze({
  home: () => `/`,
  settings: ({ companyId }: {
    companyId: string;
  }) => `/${companyId}/settings`,
  login: () => `/login`,
  loginPasswordReset: () => `/login/password-reset`,
});

export const AttendanceRoutes = Object.freeze({
  list: () => `/attendances`,
  addAttendance: () => `/attendances/add`,
});

export const FuelRoutes = Object.freeze({
  list: () => `/fuel`,
  addFuel: () => `/fuel/add`,
});

export const MaintenanceRoutes = Object.freeze({
  list: () => `/maintenances`,
  addMaintenance: () => `/maintenances/add`,
  maintenanceDetails: ({ ticketId }: { ticketId: string }) => `/maintenances/${ticketId}`,
});

export const EmployeeRoutes = Object.freeze({
  list: ({ companyId }: {
    companyId: string;
  }) => `/${companyId}/employees`,
  addEmployee: ({ companyId }: {
    companyId: string;
  }) => `/${companyId}/employees/add`,
  editEmployee: ({ companyId, employeeId }: {
    companyId: string;
    employeeId: string;
  }) => `/${companyId}/employees/${employeeId}/edit`
});

export const VehicleRoutes = Object.freeze({
  list: ({ companyId }: {
    companyId: string;
  }) => `/${companyId}/vehicles`,
  addVehicle: ({ companyId }: {
    companyId: string;
  }) => `/${companyId}/vehicles/add`,
  editVehicle: ({ companyId, vehicleId }: {
    companyId: string;
    vehicleId: string;
  }) => `/${companyId}/vehicles/${vehicleId}/edit`
});

export const WorkOrderRoutes = Object.freeze({
  list: ({ companyId }: {
    companyId: string;
  }) => `/${companyId}/workorders`,
  addWorkOrder: ({ companyId }: {
    companyId: string;
  }) => `/${companyId}/workorders/add`,
  editWorkOrder: ({ companyId, workOrderId }: {
    companyId: string;
    workOrderId: string;
  }) => `/${companyId}/workorders/${workOrderId}/edit`
});

export const TaskRoutes = Object.freeze({
  list: () => `/tasks`,
  createTask: () => `/tasks/create`,
});

export const QRRoutes = Object.freeze({
  home: () => `/qr-code`,
  scanner: () => `/qr-code/scanner`
});