import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import * as AuthUtils from '../utils/auth';
import { logout } from '../services/auth';

const UNAUTHENTICATED_ROUTES = [{ method: 'post', path: '/login' }];


const apiClient = axios.create({
  baseURL:
    process.env.REACT_APP_OETA_API_BASE_URL ||
    'http://localhost:3000/api/v1',
  timeout: 60000 * 5, //5 min
  headers: {
    'Content-Type': 'application/json',
  },
});

const isPublicUrl = ({ method, path }: any) =>
  UNAUTHENTICATED_ROUTES.some(
    (route) => method === route.method && path === route.path
  );

const request_middleware = (config: any) => {
  if (isPublicUrl({ method: config.method, path: config.url })) {
    return config;
  }

  const token = AuthUtils.getAuthCookie();
  const csrf_token = AuthUtils.getCSRFToken();

  return {
    ...cloneDeep(config),
    headers: {
      ...config.headers,
      'x-auth-token-field': token,
      'x-csrf-token': csrf_token,
      'x-app-identifier': 'field',
      // 'x-trace-id': `oeta-field=${employee_id}=${current_page}=${new Date().valueOf()}`,
    },
  };
};

const handleLogout = async () => {
  const refreshToken = AuthUtils.getRefreshToken() || '';
  AuthUtils.removeAuthCookie();
  AuthUtils.removeRefreshToken();
  await logout({
    refreshToken,
  });
}

const errorHandler = async (error: any) => {
  if (error.response && error.response.status === 401) {
    const originalRequest = error.config;
    let refreshToken = AuthUtils.getRefreshToken();

    // Refresh the token
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_OETA_API_BASE_URL}/auth/refresh-tokens`,
        { refreshToken }
      );

      if (response.status === 200) {
        const newAccessToken = response.data.access.token;
        const newRefreshToken = response.data.refresh.token;

        AuthUtils.saveAuthCookie(newAccessToken);
        AuthUtils.setRefreshToken({ token: newRefreshToken });

        // Update the headers with the new token
        originalRequest.headers['x-auth-token-field'] = newAccessToken;

        // Retry the original request
        return apiClient(originalRequest);
      } else {
        await handleLogout();
        return Promise.reject(error);
      }
    } catch (refreshError) {
      await handleLogout();
      return Promise.reject(refreshError);
    }
  }
  if (error.response && error.response.status === 403) {
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

apiClient.interceptors.request.use(request_middleware, (error) =>
  Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  errorHandler
);

export { apiClient };
