import React from 'react'
import { Outlet } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'

import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'

const AuthLayout = () => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Outlet />
    </Box>
  )
}

export default AuthLayout
