import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Loadable from '../components/common/Loadable'

const LoginForm = Loadable(lazy(() => import('../components/Login')))

const AuthRoutes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginForm />,
  },
]

export default AuthRoutes
