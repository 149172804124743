import { apiClient } from '../utils/apiClient';
import { User } from '../types';
interface CompanyBasic {
  status: string;
  companyName: string;
  goLiveDate: string;
  companyLogoDark: string;
  industryId: string;
}

interface UserProfileResponse {
  user: User,
  company: CompanyBasic
}

export function fetchProfile() {
  return apiClient.get<UserProfileResponse>(`/auth/profile`);
}

export const login = ({
  mobile,
  password,
  recaptcha,
}: {
  mobile: string;
  password: string;
  recaptcha?: string | null;
}) =>
  apiClient
    .post(`/auth/login`, {
      mobile,
      password,
      recaptcha,
    })
    .then(({ data }) => {
      return data;
    });

  export const logout = ({
    refreshToken,
  }: {
    refreshToken: string;
  }) =>
    apiClient
      .post(`/auth/logout`, {
        refreshToken
      });
  

export const validateRefreshToken = ({
  refreshToken,
}: {
  refreshToken: string;
}) => {
  return apiClient
  .post(`/auth/refresh-tokens`, {
    refreshToken
  });
}