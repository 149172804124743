import cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import dayjs from "dayjs";

const { VITE_AUTH_COOKIE_DOMAIN } = process.env;

export const CSRF_COOKIE_NAME = "x-csrf-token";
export const AUTH_COOKIE_NAME = "x-auth-token-field";

export const isAuthCookiePresent = () => Boolean(cookies.get(AUTH_COOKIE_NAME));

export const saveAuthCookie = (cookieValue: string) => {
  cookies.set(AUTH_COOKIE_NAME, `${cookieValue}`, {
    domain: VITE_AUTH_COOKIE_DOMAIN,
    secure: true,
    sameSite: "Lax",
    hostOnly: true,
  });
};

export const removeAuthCookie = () => cookies.remove(AUTH_COOKIE_NAME);
export const getAuthCookie = () => cookies.get(AUTH_COOKIE_NAME);
export const getCSRFToken = () => cookies.get(CSRF_COOKIE_NAME);

export const getRefreshToken = () => localStorage.getItem('oeta-field-refresh-token');
export const setRefreshToken = ({ token }: { token: string }) => localStorage.setItem('oeta-field-refresh-token', token);
export const removeRefreshToken = () => localStorage.removeItem('oeta-field-refresh-token');


export const isJwtExpired = (jwt: string) => {
  try {
    const { exp }: { exp: number } = jwtDecode(jwt);
    const expirationDate = dayjs(exp * 1000);
    const now = dayjs();
    return now.isAfter(expirationDate);
  } catch (error) {
    console.error(error);
    throw new Error("Error decoding JWT");
  }
};
