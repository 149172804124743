import React from 'react'
import { styled } from '@mui/material/styles'

const LoaderImg = styled('img')({
  position: 'absolute',
  left: '42%',
  top: '44%',
  width: 50,
})

const Loader = () => <LoaderImg src="/loader.svg" alt="Loader" />

export default Loader
