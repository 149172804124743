import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'

import { useUserContext } from '../contexts/UserContext'
import { Typography, Divider } from '@mui/material'
import { TypographyVariant } from '../constants/properties'
import * as AuthUtils from '../utils/auth'
import { RootRoutes, QRRoutes } from '../utils/routeGenerator'

import { logout } from '../services/auth'

export default function PrimarySearchAppBar({ setOpen }: { setOpen: any }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)
  const { user } = useUserContext()
  const navigate = useNavigate()

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleLogout = async () => {
    const refreshToken = AuthUtils.getRefreshToken() || ''
    AuthUtils.removeAuthCookie()
    await logout({
      refreshToken,
    })
    AuthUtils.removeRefreshToken()
    navigate(RootRoutes.login())
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography
        variant={TypographyVariant.BODY2}
        sx={{ padding: '10px 20px' }}
      >
        {user?.firstName} {user?.lastName} <br />
        {user?.mobile}
      </Typography>
      <Divider />
      <MenuItem onClick={() => navigate(QRRoutes.home())}>
        Generate QR-Code
      </MenuItem>
      <MenuItem onClick={() => navigate(QRRoutes.scanner())}>
        QR Scanner
      </MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  )

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant={TypographyVariant.H6}>
            Hi, {user?.firstName}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  )
}
