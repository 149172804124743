import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Paper from '@mui/material/Paper'
import styled from '@emotion/styled'

import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import ConstructionIcon from '@mui/icons-material/Construction'
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AddTaskIcon from '@mui/icons-material/AddTask'

import {
  AttendanceRoutes,
  MaintenanceRoutes,
  FuelRoutes,
  TaskRoutes,
  RootRoutes,
} from '../utils/routeGenerator'

const StyledBottomNavigation = styled(BottomNavigation)`
  &.MuiBottomNavigation-root {
    height: 70px;
    align-items: baseline;
    padding-top: 10px;
  }
`

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState<number>(0)
  const navigate = useNavigate()
  const location = useLocation()

  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (location.pathname.includes('reading')) {
      setValue(0)
    } else if (location.pathname.includes('fuel')) {
      setValue(1)
    } else if (location.pathname.includes('maintenances')) {
      setValue(2)
    } else if (location.pathname.includes('tasks')) {
      setValue(3)
    } else if (location.pathname.includes('attendances')) {
      setValue(4)
    }
  }, [location])

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <StyledBottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
            if (newValue === 0) {
              navigate(RootRoutes.home())
            } else if (newValue === 1) {
              navigate(FuelRoutes.list())
            } else if (newValue === 2) {
              navigate(MaintenanceRoutes.list())
            } else if (newValue === 3) {
              navigate(TaskRoutes.list())
            } else if (newValue === 4) {
              navigate(AttendanceRoutes.list())
            }
          }}
        >
          <BottomNavigationAction label="Reading" icon={<NetworkCheckIcon />} />
          <BottomNavigationAction label="Fuel" icon={<LocalGasStationIcon />} />
          <BottomNavigationAction
            label="Maintenance"
            icon={<ConstructionIcon />}
          />
          <BottomNavigationAction label="Task" icon={<AddTaskIcon />} />
          <BottomNavigationAction label="Attendance" icon={<EditNoteIcon />} />
        </StyledBottomNavigation>
      </Paper>
    </Box>
  )
}
