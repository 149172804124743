export enum SvgIconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  MEDIUM_LARGE = 'medium-large',
  LARGE = 'large',
  XL = 'xl',
}

export enum SnackbarVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  DEFAULT = 'defulat',
  WARNING = 'warning',
  INFO = 'info',
}

export enum TypographyVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  SUBTITLE1 = 'subtitle1',
  SUBTITLE2 = 'subtitle2',
  BODY1 = 'body1',
  BODY2 = 'body2',
  BUTTON = 'button',
  CAPTION = 'caption',
  OVERLINE = 'overline',
}

export enum SvgIconColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DISABLED = 'disabled',
  NEGATIVE = 'negative',
  WARNING = 'warning',
  POSITIVE = 'positive',
  INTERACTIVE = 'interactive',
  LIGHT = 'light',
  LINK_HOVER = 'link_hover',
}

export enum Elevation {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
}

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

export enum ButtonColor {
  SUCCESS = 'success',
  ERROR = 'error',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  INFO = 'info',
}

export enum ButtonVariant {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum IconButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

export enum IconButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DANGER = 'danger',
  GHOST = 'ghost',
}

export enum IconButtonSize {
  SMALL = 'small',
  LARGE = 'large',
}

export enum TextFieldType {
  PASSWORD = 'password',
  NUMBER = 'number',
  TEXT = 'text',
  EMAIL = 'email',
}

export enum TooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum MenuHorizontalAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum StepperStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  COMPLETED = 'completed',
  DISABLED = 'disabled',
}

export enum AreaChartType {
  CURRENCY = 'currency',
  PERCENT = 'percent',
}

export enum CheckboxLabelPlacement {
  BOTTOM = 'bottom',
  TOP = 'top',
  START = 'start',
  END = 'end',
}

export enum SwitchLabelPlacement {
  BOTTOM = 'bottom',
  TOP = 'top',
  START = 'start',
  END = 'end',
}

export enum DatePickerVariant {
  OUTLINED = 'outlined',
  FILLED = 'filled',
  STANDARD = 'standard',
}

export enum TextFieldVariant {
  OUTLINED = 'outlined',
  FILLED = 'filled',
  STANDARD = 'standard',
}

export enum SelectVariant {
  OUTLINED = 'outlined',
  FILLED = 'filled',
}

export enum SkeletonVariant {
  TEXT = 'text',
  CIRCULAR = 'circular',
  RECTANGULAR = 'rectangular',
  ROUNDED = 'rounded',
}

export enum DrawerPosition {
  BOTTOM = 'bottom',
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum CalloutVariant {
  INFO = 'info',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export enum BannerVariant {
  STANDARD = 'standard',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum ExpansionPanelVariant {
  CARD = 'card',
  FAQ = 'faq',
}

export enum ChipVariant {
  PRIMARY = 'primary',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  HIGHLIGHT = 'highlight',
}

export enum BreadcrumbSeparator {
  SLASH = 'slash',
  CHEVRON = 'chevron',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface ChartFooterProps {
  title?: string;
  customComponent?: React.ReactNode;
  captionConfig?: {
    alignment?: 'center' | 'flex-end';
    text?: string;
  };
}

export interface ChartTabProps {
  items: Array<{ text: string }>;
  onTabSelect: (tab: string) => void;
}

//directional pointers that needs to be used in locale pointer component in client app.  Used for RTL languages
export enum DirectionalPointerType {
  ARROW_RIGHT = 'arrow-right',
  ARROW_LEFT = 'arrow-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_LEFT = 'chevron-left',
}
