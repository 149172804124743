import { RouteObject } from 'react-router-dom'
import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import Loadable from '../components/common/Loadable'

import * as AuthUtils from '../utils/auth'
import { RootRoutes } from '../utils/routeGenerator'

const MeterReading = Loadable(lazy(() => import('../components/MeterReading')))
const AddMeterReading = Loadable(
  lazy(() => import('../components/MeterReading/AddReading'))
)
const Fuel = Loadable(lazy(() => import('../components/Fuel')))
const AddFuel = Loadable(lazy(() => import('../components/Fuel/AddFuel')))

const Attendance = Loadable(lazy(() => import('../components/Attendance')))
const AddAttendance = Loadable(
  lazy(() => import('../components/Attendance/AddAttendance'))
)
const Maintenance = Loadable(lazy(() => import('../components/Maintenance')))
const AddMaintenance = Loadable(
  lazy(() => import('../components/Maintenance/AddMaintenance'))
)
const MaintenanceDetails = Loadable(
  lazy(() => import('../components/Maintenance/MaintenanceDetails'))
)

const Task = Loadable(lazy(() => import('../components/Task')))

const CreateTask = Loadable(lazy(() => import('../components/Task/CreateTask')))

const QRGenerator = Loadable(lazy(() => import('../components/QRCode')))
const QRScanner = Loadable(lazy(() => import('../components/QRCode/Scanner')))

const PrivateRoute = ({ children }: { children: JSX.Element }) =>
  AuthUtils.isAuthCookiePresent() ? (
    children
  ) : (
    <Navigate to={RootRoutes.login()} />
  )

const MainRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <PrivateRoute>
        <MeterReading />
      </PrivateRoute>
    ),
  },
  {
    path: '/reading',
    element: (
      <PrivateRoute>
        <MeterReading />
      </PrivateRoute>
    ),
  },
  {
    path: '/reading/add',
    element: (
      <PrivateRoute>
        <AddMeterReading />
      </PrivateRoute>
    ),
  },
  {
    path: '/attendances',
    element: (
      <PrivateRoute>
        <Attendance />
      </PrivateRoute>
    ),
  },
  {
    path: '/attendances/add',
    element: (
      <PrivateRoute>
        <AddAttendance />
      </PrivateRoute>
    ),
  },
  {
    path: '/fuel',
    element: (
      <PrivateRoute>
        <Fuel />
      </PrivateRoute>
    ),
  },
  {
    path: '/fuel/add',
    element: (
      <PrivateRoute>
        <AddFuel />
      </PrivateRoute>
    ),
  },
  {
    path: '/maintenances',
    element: (
      <PrivateRoute>
        <Maintenance />
      </PrivateRoute>
    ),
  },
  {
    path: '/maintenances/add',
    element: (
      <PrivateRoute>
        <AddMaintenance />
      </PrivateRoute>
    ),
  },
  {
    path: '/maintenances/:maintenanceId',
    element: (
      <PrivateRoute>
        <MaintenanceDetails />
      </PrivateRoute>
    ),
  },
  {
    path: '/tasks',
    element: (
      <PrivateRoute>
        <Task />
      </PrivateRoute>
    ),
  },
  {
    path: '/tasks/create',
    element: (
      <PrivateRoute>
        <CreateTask />
      </PrivateRoute>
    ),
  },
  {
    path: '/qr-code',
    element: (
      <PrivateRoute>
        <QRGenerator />
      </PrivateRoute>
    ),
  },
  {
    path: '/qr-code/scanner',
    element: (
      <PrivateRoute>
        <QRScanner />
      </PrivateRoute>
    ),
  },
]

export default MainRoutes

// export const filteredMainRoutes = isAuthCookiePresent()
//   ? MainRoutes
//   : MainRoutes.filter(
//       (route) => !route.path || !route.path.startsWith('/dashboard')
//     )
