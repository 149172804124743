import Typography from '@mui/material/Typography'
import { TypographyVariant } from '../../constants/properties'

export default function RecipeReviewCard() {
  return (
    <Typography
      variant={TypographyVariant.H5}
      sx={{ textAlign: 'center', pt: 2 }}
    >
      Page not found.
    </Typography>
  )
}
