import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { MainRoutes, AuthRoutes } from './routes'

import { MainLayout } from './layouts/MainLayout'
import AuthLayout from './layouts/AuthLayout'
import NotFound from './components/NotFound'

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          {MainRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path as string}
              element={route.element}
            />
          ))}
        </Route>
        <Route element={<AuthLayout />}>
          {AuthRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path as string}
              element={route.element}
            />
          ))}
        </Route>
        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default App
