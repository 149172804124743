import React, { useState, createContext, useContext } from 'react'
import { Grid, CssBaseline } from '@mui/material'

import { Outlet, useOutlet, OutletProps } from 'react-router-dom'
import AppBar from './AppBar'
import SwipeableTemporaryDrawer from './Drawer'
import FixedBottomNavigation from './BottomNavigation'

type AppContextType = {
  toggleDrawer: () => void
}

const AppContext = createContext<AppContextType | undefined>(undefined)

const MainLayout = () => {
  const [open, setOpen] = useState(false)
  const setIsOpen = () => {
    setOpen(!open)
  }

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <AppContext.Provider value={{ toggleDrawer }}>
      <CssBaseline />
      <Grid container direction="column">
        <Grid item>
          {/* <SwipeableTemporaryDrawer isOpen={open} setOpen={setIsOpen} /> */}
          <AppBar setOpen={setIsOpen} />
        </Grid>
        <Grid item sx={{ p: 2 }}>
          <Outlet />
        </Grid>
        <Grid item>
          <FixedBottomNavigation />
        </Grid>
      </Grid>
    </AppContext.Provider>
  )
}

const useAppContext = () => {
  const contexts = useContext(AppContext)

  if (!contexts) {
    throw new Error('No App Contexts!')
  }

  return contexts
}

export { MainLayout, useAppContext }
