import React, { createContext, useContext, useState, useEffect } from 'react'
import { Alert, Button } from '@mui/material'
import styled from '@emotion/styled'
import SyncIcon from '@mui/icons-material/Sync'

import Loader from '../components/common/Loader'

import useQueryUser from '../hooks/useQueryUser'
import * as AuthUtils from '../utils/auth'
import { User } from '../types'
import { ButtonVariant } from '../constants/properties'

const StyledAlert = styled(Alert)`
  padding: 30px 15px;
  max-width: 300px;
`

const Container = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`

interface CompanyBasic {
  status: string
  companyName: string
  goLiveDate: string
  companyLogoDark: string
  industryId: string
}
interface UserContextType {
  user: User
  company: CompanyBasic
  setUser: ({ user, company }: { user: User; company: CompanyBasic }) => void
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<{
    user: User
    company: CompanyBasic
  } | null>(null)

  const isCookiePresent = AuthUtils.isAuthCookiePresent()

  const { data: userData, isLoading: isProfileLoading } = useQueryUser({
    enabled: isCookiePresent,
  })

  useEffect(() => {
    if (userData) {
      setUser(userData)
    }
  }, [userData])

  if (isProfileLoading && !user) {
    return <Loader />
  }

  const userInfo = user!

  return (
    <UserContext.Provider
      value={{ company: userInfo?.company, user: userInfo?.user, setUser }}
    >
      {userInfo && !userInfo?.user.isActive && isCookiePresent ? (
        <Container>
          <StyledAlert severity="warning">
            Hi {userInfo?.user.firstName}, Your account is not active. Please
            contact your manager.
          </StyledAlert>
          <Button
            variant={ButtonVariant.TEXT}
            startIcon={<SyncIcon />}
            sx={{ mt: 3 }}
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </Container>
      ) : (
        <>{children}</>
      )}
    </UserContext.Provider>
  )
}

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}
