import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "../services/auth";

const getProfile = async () => {
  const { data } = await fetchProfile();
  return data;
};

const useQueryUser = ({ enabled = false }: { enabled?: boolean }) => {
  const {
    data,
    isLoading: isQueryLoading,
    refetch,
  } = useQuery({
    queryKey: ["getProfile"],
    queryFn: () => getProfile(),
    enabled,
  });

  const isLoading = enabled ? isQueryLoading : false;

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useQueryUser;
